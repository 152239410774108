import React, { useState, useEffect } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import CTAButton from './CTAButton';
import SecondaryButton from './SecondaryButton';
import '../Styles/CasesSlideshow.css';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

function CasesSlideshow() {
  const [cases, setCases] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchCases = async () => {
      const casesCollection = collection(db, 'cases');
      const q = query(casesCollection, orderBy('createdAt', 'desc'), limit(isMobile ? 3 : 4));
      const querySnapshot = await getDocs(q);
      const casesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCases(casesData);
    };

    fetchCases();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  return (
    <div className="cases-slideshow">
      <div className="cases-grid">
        {cases.map((caseItem) => (
          <Link to={`/case/${caseItem.id}`} key={caseItem.id} className="case-item">
            <div className="case-image-container">
              <div className="case-image">
                <img src={caseItem.image} alt={caseItem.company} />
              </div>
            </div>
            <div className="case-info">
              <div className="case-details">
                <h3>{caseItem.company}</h3>
                <p>{caseItem.displayLink}</p>
              </div>
              <div className="case-button" aria-label="Se mere">
                <HiArrowNarrowRight />
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="cases-buttons">
        <Link to="/cases">
          <SecondaryButton text="Se flere" />
        </Link>
        <CTAButton text="Kontakt os" navigateTo="/kontakt" />
      </div>
    </div>
  );
}

export default CasesSlideshow;
