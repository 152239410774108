import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc, serverTimestamp, setDoc, query, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { OpenAI } from "openai";
import '../Styles/Admin.css';
import AdminContract from '../Components/adminContract';
import Beskeder from '../Components/beskeder';
import { useLocation } from 'react-router-dom';

function Admin() {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [caseData, setCaseData] = useState({
    colors: ['', '', '', ''],
    company: '',
    description: '',
    fonts: ['', '', ''],
    title: '',
    website: '',
    testimonial: '',
    testimonialAuthor: '',
    displayLink: '',
    year: '' // Tilføj dette nye felt
  });
  const [images, setImages] = useState({
    logo: null,
    image: null,
    image_phone: null
  });
  const [cases, setCases] = useState([]);
  const [editingCase, setEditingCase] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState({
    logo: '',
    image: '',
    image_tablet: '',
    image_phone: ''
  });
  const [isImageSelectionOpen, setIsImageSelectionOpen] = useState(false);
  const [currentImageType, setCurrentImageType] = useState('');
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const [openaiClient, setOpenaiClient] = useState(null);
  const [outletTemplates, setOutletTemplates] = useState([]);
  const [isOutletPopupOpen, setIsOutletPopupOpen] = useState(false);
  const [outletData, setOutletData] = useState({
    id: '',
    title: '',
    description: '',
    price: '',
    demoBaseUrl: '',
    features: ['', '', '', '', '', ''],
    images: [],
    isActive: true
  });
  const [outletImages, setOutletImages] = useState([]);
  const [showContract, setShowContract] = useState(false);
  const [activeSection, setActiveSection] = useState('websites');

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  const location = useLocation();

  useEffect(() => {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (apiKey) {
      setOpenaiClient(new OpenAI({
        apiKey: apiKey,
        dangerouslyAllowBrowser: true
      }));
    } else {
      console.error('OpenAI API-nøgle mangler. Sørg for at definere REACT_APP_OPENAI_API_KEY i .env filen.');
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      fetchCases();
      fetchOutletTemplates();
    }
  }, [user]);

  useEffect(() => {
    // Tjek URL parameteren når komponenten indlæses
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    if (tabParam === 'beskeder') {
      setActiveSection('beskeder');
    }
  }, [location]);

  const fetchCases = async () => {
    const casesCollection = collection(db, 'cases');
    const casesSnapshot = await getDocs(casesCollection);
    const casesList = casesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCases(casesList);
  };

  const fetchOutletTemplates = async () => {
    try {
      const templatesQuery = query(
        collection(db, 'outlet_templates'),
        where('isActive', '==', true)
      );
      const templatesSnapshot = await getDocs(templatesQuery);
      const templatesList = templatesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Sorter templates efter updatedAt i faldende rækkefølge
      templatesList.sort((a, b) => b.updatedAt.seconds - a.updatedAt.seconds);

      setOutletTemplates(templatesList);
    } catch (error) {
      console.error('Fejl ved hentning af templates:', error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Fejl ved login:', error);
      alert('Fejl ved login. Prøv igen.');
    }
  };

  const handleLogout = () => {
    signOut(auth);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCaseData(prev => ({ ...prev, [name]: value }));
  };

  const handleArrayInputChange = (e, index, field) => {
    const { value } = e.target;
    setCaseData(prev => {
      const newArray = [...prev[field]];
      newArray[index] = value;
      return { ...prev, [field]: newArray };
    });
  };

  const handleImageChange = (e, type) => {
    setImages(prev => ({ ...prev, [type]: e.target.files[0] }));
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const newUploadedImages = [];

    for (const file of files) {
      const storageRef = ref(storage, `Cases/${caseData.company}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      newUploadedImages.push({ name: file.name, url });
    }

    setUploadedImages([...uploadedImages, ...newUploadedImages]);
  };

  const openImageSelection = (type) => {
    setCurrentImageType(type);
    setIsImageSelectionOpen(true);
  };

  const handleImageSelection = (url) => {
    setSelectedImages(prev => ({ ...prev, [currentImageType]: url }));
    setIsImageSelectionOpen(false);
  };

  const generateDescription = async () => {
    if (!openaiClient) {
      console.error('OpenAI klient er ikke initialiseret');
      alert('OpenAI klient er ikke initialiseret. Tjek din API-nøgle.');
      return;
    }
    setIsGeneratingDescription(true);
    try {
      console.log('Starter generering af beskrivelse');
      const prompt = `Du arbejder for en virksomhed, der skaber hjemmesider. Vi har lavet en hjemmeside til følgende kunde: ${caseData.company}. Lad være med at inkludere en eventuel testimonial i beskrivelsen. Skriv en kort og professionel beskrivelse af en case for følgende virksomhed: ${caseData.company}.
      Brug følgende information som udgangspunkt:
      Beskrivelse: ${caseData.description}
      ${caseData.testimonial ? `Testimonial: ${caseData.testimonial}` : ''}
      ${caseData.website ? `Hjemmeside: ${caseData.website}` : ''}
      
      Inkluder relevante detaljer fra testimonial og hjemmeside, hvis de er tilgængelige. Beskrivelsen skal være uformel og skal fange interessen af en potentiel kunde.`;

      const response = await openaiClient.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: "Du er en hjælpsom assistent, der skriver korte og uformelle casebeskrivelser for en virksomhed der laver webdesign." },
          { role: "user", content: prompt }
        ],
        max_tokens: 300,
        n: 1,
        temperature: 0.7,
      });
      console.log('Svar modtaget fra OpenAI:', response);
      setCaseData(prev => ({ ...prev, description: response.choices[0].message.content.trim() }));
    } catch (error) {
      console.error('Detaljeret fejl ved generering af beskrivelse:', error);
      if (error.response) {
        console.error('OpenAI API svar:', error.response.data);
      }
      alert(`Der opstod en fejl ved generering af beskrivelse: ${error.message}`);
    }
    setIsGeneratingDescription(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const caseDataToSubmit = {
        ...caseData,
        ...selectedImages,
        updatedAt: serverTimestamp()
      };

      if (editingCase) {
        await updateDoc(doc(db, 'cases', editingCase.id), caseDataToSubmit);
        alert('Case opdateret med succes!');
      } else {
        const companyDocRef = doc(db, 'cases', caseData.company); // Brug virksomhedens navn som Document ID
        await setDoc(companyDocRef, {
          ...caseDataToSubmit,
          createdAt: serverTimestamp()
        });
        alert('Case tilføjet med succes!');
      }

      resetForm();
      fetchCases();
      closePopup();
    } catch (error) {
      console.error('Fejl ved håndtering af case:', error);
      alert('Der opstod en fejl. Prøv igen.');
    }
  };

  const resetForm = () => {
    setCaseData({
      colors: ['', '', '', ''],
      company: '',
      description: '',
      fonts: ['', '', ''],
      title: '',
      website: '',
      testimonial: '',
      testimonialAuthor: '',
      displayLink: '',
      year: '' // Tilføj dette nye felt
    });
    setImages({
      logo: null,
      image: null,
      image_phone: null
    });
    setEditingCase(null);
    setUploadedImages([]);
    setSelectedImages({
      logo: '',
      image: '',
      image_tablet: '',
      image_phone: ''
    });
  };

  const handleEdit = (caseItem) => {
    setCaseData({
      colors: caseItem.colors || ['', '', '', ''],
      company: caseItem.company || '',
      description: caseItem.description || '',
      fonts: caseItem.fonts || ['', '', ''],
      title: caseItem.title || '',
      website: caseItem.website || '',
      testimonial: caseItem.testimonial || '',
      testimonialAuthor: caseItem.testimonialAuthor || '',
      displayLink: caseItem.displayLink || '',
      year: caseItem.year || '' // Tilføj dette nye felt
    });
    setSelectedImages({
      logo: caseItem.logo || '',
      image: caseItem.image || '',
      image_tablet: caseItem.image_tablet || '',
      image_phone: caseItem.image_phone || ''
    });
    setUploadedImages([
      { name: 'Logo', url: caseItem.logo },
      { name: 'PC-billede', url: caseItem.image },
      { name: 'Tablet-billede', url: caseItem.image_tablet },
      { name: 'Mobil-billede', url: caseItem.image_phone }
    ].filter(img => img.url)); // Fjern eventuelle tomme billeder
    setEditingCase(caseItem);
    setIsPopupOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne case?')) {
      try {
        await deleteDoc(doc(db, 'cases', id));
        alert('Case slettet med succes!');
        fetchCases();
      } catch (error) {
        console.error('Fejl ved sletning af case:', error);
        alert('Der opstod en fejl ved sletning. Prøv igen.');
      }
    }
  };

  const handleAddNew = () => {
    resetForm();
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    resetForm();
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains('admin-popup-overlay')) {
      closePopup();
    }
  };

  const handleOutletSubmit = async (e) => {
    e.preventDefault();
    try {
      const templateRef = doc(db, 'outlet_templates', outletData.id);
      await setDoc(templateRef, {
        ...outletData,
        updatedAt: serverTimestamp()
      });
      
      setIsOutletPopupOpen(false);
      fetchOutletTemplates();
      alert('Template gemt med succes!');
    } catch (error) {
      console.error('Fejl ved gem af template:', error);
      alert('Der opstod en fejl ved gem af template');
    }
  };

  const handleOutletInputChange = (e) => {
    const { name, value } = e.target;
    setOutletData(prev => ({ ...prev, [name]: value }));
  };

  const handleFeatureChange = (index, value) => {
    setOutletData(prev => {
      const newFeatures = [...prev.features];
      newFeatures[index] = value;
      return { ...prev, features: newFeatures };
    });
  };

  const closeOutletPopup = () => {
    setIsOutletPopupOpen(false);
    setOutletData({
      id: '',
      title: '',
      description: '',
      price: '',
      demoBaseUrl: '',
      features: ['', '', '', '', '', ''],
      images: [],
      isActive: true
    });
  };

  const handleDeleteTemplate = async (templateId) => {
    if (window.confirm('Er du sikker på, at du vil slette denne template?')) {
      try {
        await deleteDoc(doc(db, 'outlet_templates', templateId));
        alert('Template slettet med succes!');
        fetchOutletTemplates();
      } catch (error) {
        console.error('Fejl ved sletning af template:', error);
        alert('Der opstod en fejl ved sletning af template');
      }
    }
  };

  const handleEditTemplate = (template) => {
    setOutletData({
      ...template,
      features: [...template.features]
    });
    setIsOutletPopupOpen(true);
  };

  const handleOutletImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const newUploadedImages = [];

    try {
      for (const file of files) {
        const storageRef = ref(storage, `outlet_templates/${outletData.id}/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        newUploadedImages.push(url);
      }

      setOutletData(prev => ({
        ...prev,
        images: [...prev.images, ...newUploadedImages]
      }));
      
    } catch (error) {
      console.error('Fejl ved upload af billeder:', error);
      alert('Der opstod en fejl ved upload af billeder');
    }
  };

  const handleRemoveOutletImage = (indexToRemove) => {
    setOutletData(prev => ({
      ...prev,
      images: prev.images.filter((_, index) => index !== indexToRemove)
    }));
  };

  if (!user) {
    return (
      <div className="admin-login">
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Adgangskode"
            required
          />
          <button type="submit">Log ind</button>
        </form>
      </div>
    );
  }

  return (
    <div className="admin-page">
      <div className="admin-content">
        <h1>Admin Dashboard</h1>
        <button onClick={handleLogout} className="admin-logout-button">Log ud</button>
        
        <div className="admin-navigation">
          <button 
            className={`admin-nav-button ${activeSection === 'websites' ? 'active' : ''}`}
            onClick={() => setActiveSection('websites')}
          >
            Hjemmesider
          </button>
          <button 
            className={`admin-nav-button ${activeSection === 'contracts' ? 'active' : ''}`}
            onClick={() => setActiveSection('contracts')}
          >
            Kontrakter
          </button>
          <button 
            className={`admin-nav-button ${activeSection === 'messages' ? 'active' : ''}`}
            onClick={() => setActiveSection('messages')}
          >
            Beskeder
          </button>
        </div>

        {activeSection === 'websites' ? (
          <>
            <button onClick={handleAddNew} className="admin-add-case-button">Tilføj ny case</button>
            <div className="admin-cases-list">
              {cases.map(caseItem => (
                <div key={caseItem.id} className="admin-case-item">
                  <h3>{caseItem.company}</h3>
                  <p>{caseItem.title}</p>
                  <button onClick={() => handleEdit(caseItem)}>Rediger</button>
                  <button onClick={() => handleDelete(caseItem.id)}>Slet</button>
                </div>
              ))}
            </div>

            {isPopupOpen && (
              <div className="admin-popup-overlay" onClick={handleClickOutside}>
                <div className="admin-popup">
                  <h2>{editingCase ? 'Rediger case' : 'Tilføj ny case'}</h2>
                  <form onSubmit={handleSubmit} className="admin-case-form">
                    <div className="admin-form-group">
                      <label htmlFor="company">Virksomhedsnavn</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={caseData.company}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="admin-form-group">
                      <label htmlFor="website">Hjemmeside</label>
                      <input
                        type="text"
                        id="website"
                        name="website"
                        value={caseData.website}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="admin-form-group">
                      <label htmlFor="displayLink">Vist link</label>
                      <input
                        type="text"
                        id="displayLink"
                        name="displayLink"
                        value={caseData.displayLink}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="admin-form-group">
                      <label htmlFor="description">Beskrivelse</label>
                      <div className="description-input-container">
                        <textarea
                          id="description"
                          name="description"
                          value={caseData.description}
                          onChange={handleInputChange}
                          required
                        />
                        <button 
                          type="button" 
                          className="ai-generate-button"
                          onClick={generateDescription}
                          disabled={isGeneratingDescription}
                        >
                          {isGeneratingDescription ? 'Genererer...' : 'AI'}
                        </button>
                      </div>
                    </div>
                    <div className="admin-form-group">
                      <label htmlFor="testimonial">Testimonial</label>
                      <textarea
                        id="testimonial"
                        name="testimonial"
                        value={caseData.testimonial}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="admin-form-group">
                      <label htmlFor="testimonialAuthor">Testimonial forfatter</label>
                      <input
                        type="text"
                        id="testimonialAuthor"
                        name="testimonialAuthor"
                        value={caseData.testimonialAuthor}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="admin-colors-section">
                      <h3>Farver</h3>
                      {caseData.colors.map((color, index) => (
                        <div key={index} className="admin-form-group">
                          <label htmlFor={`color-${index}`}>Farve {index + 1}</label>
                          <input
                            id={`color-${index}`}
                            type="text"
                            value={color}
                            onChange={(e) => handleArrayInputChange(e, index, 'colors')}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="admin-fonts-section">
                      <h3>Skrifttyper</h3>
                      {caseData.fonts.map((font, index) => (
                        <div key={index} className="admin-form-group">
                          <label htmlFor={`font-${index}`}>Skrifttype {index + 1}</label>
                          <input
                            id={`font-${index}`}
                            type="text"
                            value={font}
                            onChange={(e) => handleArrayInputChange(e, index, 'fonts')}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="admin-images-section">
                      <h3>Upload billeder</h3>
                      <input
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                        accept="image/*"
                      />
                      <div className="uploaded-images">
                        {uploadedImages.map((img, index) => (
                          <img key={index} src={img.url} alt={img.name} className="uploaded-image-thumbnail" />
                        ))}
                      </div>
                      {['logo', 'image', 'image_tablet', 'image_phone'].map((type) => (
                        <div key={type} className="image-selection">
                          <h4>
                            {type === 'logo' ? 'Logo' :
                             type === 'image' ? 'PC-billede' :
                             type === 'image_tablet' ? 'Tabletbillede' :
                             'Mobilbillede'}
                          </h4>
                          <button type="button" onClick={() => openImageSelection(type)}>
                            Vælg {
                              type === 'logo' ? 'Logo' :
                              type === 'image' ? 'PC-billede' :
                              type === 'image_tablet' ? 'Tabletbillede' :
                              'Mobilbillede'
                            }
                          </button>
                          {selectedImages[type] && (
                            <img src={selectedImages[type]} alt={type} className="selected-image-preview" />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="admin-form-group">
                      <label htmlFor="year">År</label>
                      <input
                        type="number"
                        id="year"
                        name="year"
                        value={caseData.year}
                        onChange={handleInputChange}
                        min="1900"
                        max={new Date().getFullYear()}
                        required
                      />
                    </div>
                    <div className="admin-popup-buttons">
                      <button type="submit">{editingCase ? 'Opdater Case' : 'Tilføj Case'}</button>
                      <button type="button" onClick={closePopup}>Annuller</button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {isImageSelectionOpen && (
              <div className="admin-image-selection-overlay">
                <div className="admin-image-selection-popup">
                  <h3>Vælg {currentImageType === 'image_phone' ? 'Mobilbillede' : currentImageType === 'image' ? 'Tabletbillede' : 'Logo'}</h3>
                  <div className="image-grid">
                    {uploadedImages.map((img, index) => (
                      <img 
                        key={index} 
                        src={img.url} 
                        alt={img.name} 
                        className="image-selection-thumbnail" 
                        onClick={() => handleImageSelection(img.url)}
                      />
                    ))}
                  </div>
                  <button type="button" onClick={() => setIsImageSelectionOpen(false)}>Luk</button>
                </div>
              </div>
            )}

            <section className="admin-outlet-section">
              <h2>Outlet Templates</h2>
              <button onClick={() => setIsOutletPopupOpen(true)} className="admin-add-button">
                Tilføj ny template
              </button>

              <div className="admin-templates-grid">
                {outletTemplates.map(template => (
                  <div key={template.id} className="admin-template-card">
                    <h3>{template.title}</h3>
                    <p>{template.description}</p>
                    <p className="template-price">{template.price}</p>
                    <div className="template-status">
                      Status: {template.isActive ? 'Aktiv' : 'Inaktiv'}
                    </div>
                    <div className="template-actions">
                      <button onClick={() => handleEditTemplate(template)}>
                        Rediger
                      </button>
                      <button 
                        onClick={() => handleDeleteTemplate(template.id)}
                        className="delete-button"
                      >
                        Slet
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {isOutletPopupOpen && (
                <div className="admin-popup-overlay">
                  <div className="admin-popup">
                    <h2>{outletData.id ? 'Rediger template' : 'Tilføj ny template'}</h2>
                    <form onSubmit={handleOutletSubmit} className="admin-template-form">
                      <div className="admin-form-group">
                        <label htmlFor="id">Template ID</label>
                        <input
                          type="text"
                          id="id"
                          name="id"
                          value={outletData.id}
                          onChange={handleOutletInputChange}
                          required
                        />
                      </div>

                      <div className="admin-form-group">
                        <label htmlFor="title">Titel</label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          value={outletData.title}
                          onChange={handleOutletInputChange}
                          required
                        />
                      </div>

                      <div className="admin-form-group">
                        <label htmlFor="description">Beskrivelse</label>
                        <textarea
                          id="description"
                          name="description"
                          value={outletData.description}
                          onChange={handleOutletInputChange}
                          required
                        />
                      </div>

                      <div className="admin-form-group">
                        <label htmlFor="price">Pris</label>
                        <input
                          type="text"
                          id="price"
                          name="price"
                          value={outletData.price}
                          onChange={handleOutletInputChange}
                          required
                        />
                      </div>

                      <div className="admin-form-group">
                        <label htmlFor="demoBaseUrl">Demo URL</label>
                        <input
                          type="text"
                          id="demoBaseUrl"
                          name="demoBaseUrl"
                          value={outletData.demoBaseUrl}
                          onChange={handleOutletInputChange}
                          required
                        />
                      </div>

                      <div className="admin-form-group">
                        <label>Features</label>
                        {outletData.features.map((feature, index) => (
                          <input
                            key={index}
                            type="text"
                            value={feature}
                            onChange={(e) => handleFeatureChange(index, e.target.value)}
                            placeholder={`Feature ${index + 1}`}
                          />
                        ))}
                      </div>

                      <div className="admin-form-group">
                        <label>
                          <input
                            type="checkbox"
                            name="isActive"
                            checked={outletData.isActive}
                            onChange={(e) => setOutletData(prev => ({
                              ...prev,
                              isActive: e.target.checked
                            }))}
                          />
                          Aktiv
                        </label>
                      </div>

                      <div className="admin-form-group">
                        <label>Template Billeder</label>
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleOutletImageUpload}
                        />
                        <div className="outlet-images-preview">
                          {outletData.images.map((imageUrl, index) => (
                            <div key={index} className="outlet-image-container">
                              <img 
                                src={imageUrl} 
                                alt={`Template billede ${index + 1}`} 
                                className="outlet-image-thumbnail"
                              />
                              <button 
                                type="button"
                                onClick={() => handleRemoveOutletImage(index)}
                                className="remove-image-button"
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="admin-popup-buttons">
                        <button type="submit">Gem template</button>
                        <button type="button" onClick={closeOutletPopup}>Annuller</button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </section>
          </>
        ) : activeSection === 'contracts' ? (
          <AdminContract />
        ) : (
          <Beskeder />
        )}
      </div>
    </div>
  );
}

export default Admin;