import React, { useState, useEffect } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import InvertCTAButton from './InvertCTAButton';
import InvertSecondaryButton from './InvertSecondaryButton';
import '../Styles/CasesBlack.css';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

function CasesBlack() {
  const [cases, setCases] = useState([]);

  useEffect(() => {
    const fetchCases = async () => {
      const casesCollection = collection(db, 'cases');
      const q = query(casesCollection, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const casesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      // Vælg den 5. og 6. sag (indeks 4 og 5)
      setCases(casesData.slice(4, 6));
    };

    fetchCases();
  }, []);

  return (
    <div className="cases-b-slideshow">
      <div className="cases-b-grid">
        {cases.map((caseItem) => (
          <Link to={`/case/${caseItem.id}`} key={caseItem.id} className="case-b-item">
            <div className="case-b-image-container">
              <div className="case-b-image">
                <img src={caseItem.image} alt={caseItem.company} />
              </div>
            </div>
            <div className="case-b-info">
              <div className="case-b-details">
                <h3>{caseItem.company}</h3>
                <p>{caseItem.displayLink}</p>
              </div>
              <div className="case-b-button" aria-label="Se mere">
                <HiArrowNarrowRight />
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="cases-b-buttons">
        <Link to="/cases">
          <InvertSecondaryButton text="Se flere" />
        </Link>
        <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
      </div>
    </div>
  );
}

export default CasesBlack;