import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import '../Styles/adminContract.css';
import logo from '../images/PBBilleder/PBLogo.png';

function AdminContract() {
  const [contractData, setContractData] = useState({
    customerName: '',
    customerCVR: '',
    customerCompany: '',
    customerSigner: '',
    customerAddress: '',
    customerPhone: '',
    customerEmail: '',
    price: '',
    paymentInterval: 'måned', // måned/kvartal/år
    firstMeetingDate: '',
    secondMeetingDate: '',
    companySigner: 'Nicolai Malik Jeppesen'
  });

  const companyInfo = {
    name: 'Parla Branding',
    cvr: '43439928',
    address: 'Rebæk Søpark 5, 3. 441, 2650 Hvidovre',
    phone: '+45 36 17 00 00',
    email: 'kontakt@parlabranding.dk'
  };

  const companySigners = [
    'Nicolai Malik Jeppesen',
    'Mark Abildaard Christensen',
    'Oliver Juhl Wade'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContractData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    let y = 20;
    const lineHeight = 7;
    const margin = 20;
    const pageWidth = 210; // A4 bredde i mm
    
    // Tilføj header med logo og dato
    const addHeader = () => {
      return new Promise((resolve) => {
        doc.setFillColor(248, 249, 250);
        doc.rect(0, 0, pageWidth, 40, 'F');
        
        const img = new Image();
        img.src = logo;
        
        img.onload = function() {
          const imgHeight = 20;
          const aspectRatio = img.width / img.height;
          const imgWidth = imgHeight * aspectRatio;
          
          doc.addImage(img, 'PNG', margin, 5, imgWidth, imgHeight);
          
          const today = new Date().toLocaleDateString('da-DK');
          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          doc.setTextColor(51, 51, 51);
          doc.text(today, pageWidth - margin - doc.getTextWidth(today), 15);
          
          resolve();
        };
      });
    };

    // Hjælpefunktion til at ombryde tekst
    const splitTextToFit = (text, maxWidth) => {
      const words = text.split(' ');
      const lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = doc.getTextWidth(currentLine + ' ' + word);
        if (width < maxWidth) {
          currentLine += ' ' + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
      return lines;
    };

    // Opdateret addText funktion
    const addText = (text, style = 'normal') => {
      switch(style) {
        case 'title':
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(20);
          doc.setTextColor(51, 51, 51);
          break;
        case 'heading':
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(14);
          doc.setTextColor(51, 51, 51);
          break;
        case 'subheading':
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(12);
          doc.setTextColor(51, 51, 51);
          break;
        default:
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(11);
          doc.setTextColor(68, 68, 68);
      }
      
      if (y > 270) {
        doc.addPage();
        addHeader();
        y = 40;
      }
      
      // Beregn maksimal bredde baseret på margin
      const maxWidth = pageWidth - (margin * 2);
      
      // Split tekst hvis den er for lang
      const lines = splitTextToFit(text, maxWidth);
      lines.forEach(line => {
        doc.text(line, margin, y);
        y += lineHeight;
      });
    };

    // For kolonnetekst
    const addColumnText = (text, x, maxWidth) => {
      const lines = splitTextToFit(text, maxWidth);
      lines.forEach(line => {
        doc.text(line, x, y);
        y += lineHeight;
      });
    };

    // Tilføj dekorativ linje
    const addLine = () => {
      doc.setDrawColor(200, 200, 200);
      doc.line(margin, y, pageWidth - margin, y);
      y += lineHeight;
    };

    // Start PDF generering
    await addHeader();
    y = 40;

    // Titel
    addText('Kontrakt for Hjemmesideudvikling', 'title');
    addText('og Abonnement', 'title');
    y += 10;
    
    // Parter sektion
    addText('Parter', 'heading');
    addLine();
    y += 5;
    
    // Beregn midtpunkt og kolonnebredder
    const midPoint = pageWidth / 2;
    const colWidth = (pageWidth - (margin * 2)) / 2;
    
    // Gem start Y position for at sikre lige højde
    const startY = y;
    
    // Venstre kolonne (Udbyder)
    const leftColMaxWidth = colWidth - 10;
    doc.setFont('helvetica', 'bold');
    doc.text('Udbyder:', margin, y);
    y += lineHeight;
    
    doc.setFont('helvetica', 'normal');
    addColumnText(`${companyInfo.name}`, margin, leftColMaxWidth);
    addColumnText(`CVR: ${companyInfo.cvr}`, margin, leftColMaxWidth);
    addColumnText(`Underskriver: ${contractData.companySigner}`, margin, leftColMaxWidth);
    addColumnText(`${companyInfo.address}`, margin, leftColMaxWidth);
    addColumnText(`Tlf: ${companyInfo.phone}`, margin, leftColMaxWidth);
    addColumnText(`Email: ${companyInfo.email}`, margin, leftColMaxWidth);
    
    // Reset Y position og start højre kolonne
    y = startY;
    
    // Højre kolonne (Kunde)
    const rightColX = margin + colWidth + 10;
    const rightColMaxWidth = leftColMaxWidth;
    
    doc.setFont('helvetica', 'bold');
    doc.text('Kunde:', rightColX, y);
    y += lineHeight;
    
    doc.setFont('helvetica', 'normal');
    addColumnText(`${contractData.customerCompany}`, rightColX, rightColMaxWidth);
    addColumnText(`CVR: ${contractData.customerCVR}`, rightColX, rightColMaxWidth);
    addColumnText(`Underskriver: ${contractData.customerSigner}`, rightColX, rightColMaxWidth);
    addColumnText(`${contractData.customerAddress}`, rightColX, rightColMaxWidth);
    addColumnText(`Tlf: ${contractData.customerPhone}`, rightColX, rightColMaxWidth);
    addColumnText(`Email: ${contractData.customerEmail}`, rightColX, rightColMaxWidth);
    
    // Gå til næste sektion (brug den højeste Y-værdi + mellemrum)
    y += lineHeight * 2;

    // Fortsæt med resten af dokumentet
    addText('Pris', 'heading');
    addLine();
    addText(`Prisen for alt hvad siden indebærer er ${contractData.price} DKK ekskl. moms pr. ${contractData.paymentInterval}`);
    y += 10;
    
    // Aftalens Forløb og Vilkår
    addText('Aftalens Forløb og Vilkår', 'heading');
    addLine();
    y += 5;
    
    // 1. Udkast og Feedback
    addText('1. Udkast og Feedback', 'subheading');
    addText(`Udbyderen har præsenteret første udkast til hjemmesiden ved et møde den ${contractData.firstMeetingDate}.`);
    addText('Kunden har ved mødet givet feedback og forslag til ændringer.');
    addText('Ønsker kunden at fortsætte samarbejdet, underskrives denne kontrakt ved mødet, og arbejdet fortsætter.');
    y += 5;
    
    // 2. Færdiggørelse og Godkendelse
    addText('2. Færdiggørelse og Godkendelse', 'subheading');
    addText('Udbyderen færdiggør hjemmesiden baseret på kundens feedback.');
    addText(`Et nyt møde afholdes ca. 7-10 dage senere ${contractData.secondMeetingDate}, hvor udbyderen fremviser den næsten færdige hjemmeside. Kunden kan give yderligere feedback.`);
    addText('Kontrakten bliver bindende ved dette møde, medmindre kunden eksplicit afslutter samarbejdet.');
    y += 5;
    
    // 3. Betaling
    addText('3. Betaling', 'subheading');
    addText('Første faktura sendes, når hjemmesiden er klar til udgivelse eller senest 14 dage efter det bindende møde.');
    addText('Betalingsbetingelser:');
    addText('Betaling skal ske senest 8 dage efter modtagelse af faktura.');
    addText('Hjemmesiden udgives først, når betaling er modtaget.');
    y += 5;
    
    // 4. Hosting, Domænenavn og Support
    addText('4. Hosting, Domænenavn og Support', 'subheading');
    addText('Hosting: Udbyderen står for drift og vedligeholdelse.');
    addText('Domænenavn: Udbyderen køber og administrerer domænet, hvis nødvendigt.');
    addText('Support: Løbende teknisk support og rettelser ved behov.');
    y += 5;
    
    // Abonnement og Opsigelse
    addText('Abonnement og Opsigelse', 'subheading');
    addText('Aftalen løber ét år ad gangen uden binding.');
    addText('Abonnementet fornyes automatisk, medmindre det opsiges skriftligt senest 30 dage før fornyelsesdatoen.');
    y += 5;
    
    // Ejerskab
    addText('Ejerskab', 'subheading');
    addText('Hjemmesidens design ejes af udbyderen, og kunden kan ikke uden videre tage det med til en ny udbyder.');
    addText('Kunden kan dog kræve ejerskab, hvis udbyderen ændrer bindingsperiode, pris, service eller hosting.');
    y += 10;
    
    // Underskrifter
    y += 10;
    addText('Underskrifter', 'heading');
    addLine();
    addText('Ved underskrift accepterer begge parter vilkårene og forpligter sig til at opfylde deres respektive ansvar.');
    y += 10;

    // Datoer først
    doc.setFontSize(8);
    doc.text('Dato', margin, y);
    doc.text('Dato', pageWidth - margin - 70, y);

    // Datofelter med linjer
    doc.line(margin, y + 2, margin + 70, y + 2);
    doc.line(pageWidth - margin - 70, y + 2, pageWidth - margin, y + 2);

    // Mellemrum mellem dato og underskrift
    y += 20;

    // Underskriftfelter
    doc.setFontSize(8);
    doc.text('Underskrift', margin, y);
    doc.text('Underskrift', pageWidth - margin - 70, y);

    // Underskriftlinjer
    doc.line(margin, y + 2, margin + 70, y + 2);
    doc.line(pageWidth - margin - 70, y + 2, pageWidth - margin, y + 2);

    // Labels under linjerne
    doc.setFontSize(11);
    doc.text('Kunde:', margin, y + 15);
    doc.text('Udbyder:', pageWidth - margin - 70, y + 15);

    // Gem PDF'en
    doc.save('kontrakt.pdf');
  };

  return (
    <div className="admin-contract">
      <h2>Opret Kontrakt</h2>
      <form onSubmit={async (e) => {
        e.preventDefault();
        await generatePDF();
      }}>
        <div className="form-section">
          <h3>Udbyder</h3>
          <select
            name="companySigner"
            value={contractData.companySigner}
            onChange={handleInputChange}
            required
          >
            {companySigners.map(signer => (
              <option key={signer} value={signer}>
                {signer}
              </option>
            ))}
          </select>
        </div>

        <div className="form-section">
          <h3>Kundeoplysninger</h3>
          <input
            type="text"
            name="customerCompany"
            value={contractData.customerCompany}
            onChange={handleInputChange}
            placeholder="Firmanavn"
            required
          />
          <input
            type="text"
            name="customerCVR"
            value={contractData.customerCVR}
            onChange={handleInputChange}
            placeholder="CVR-nummer"
            required
          />
          <input
            type="text"
            name="customerSigner"
            value={contractData.customerSigner}
            onChange={handleInputChange}
            placeholder="Underskriver"
            required
          />
          <input
            type="text"
            name="customerAddress"
            value={contractData.customerAddress}
            onChange={handleInputChange}
            placeholder="Adresse"
            required
          />
          <input
            type="tel"
            name="customerPhone"
            value={contractData.customerPhone}
            onChange={handleInputChange}
            placeholder="Telefon"
            required
          />
          <input
            type="email"
            name="customerEmail"
            value={contractData.customerEmail}
            onChange={handleInputChange}
            placeholder="Email"
            required
          />
        </div>

        <div className="form-section">
          <h3>Prisoplysninger</h3>
          <input
            type="number"
            name="price"
            value={contractData.price}
            onChange={handleInputChange}
            placeholder="Pris (ekskl. moms)"
            required
          />
          <select
            name="paymentInterval"
            value={contractData.paymentInterval}
            onChange={handleInputChange}
            required
          >
            <option value="måned">Pr. måned</option>
            <option value="kvartal">Pr. kvartal</option>
            <option value="år">Pr. år</option>
          </select>
        </div>

        <div className="form-section">
          <h3>Mødedatoer</h3>
          <input
            type="date"
            name="firstMeetingDate"
            value={contractData.firstMeetingDate}
            onChange={handleInputChange}
            required
          />
          <input
            type="date"
            name="secondMeetingDate"
            value={contractData.secondMeetingDate}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit">Generer Kontrakt</button>
      </form>
    </div>
  );
}

export default AdminContract;
