import React from 'react';
import '../Styles/datapolitik.css';

function Datapolitik() {
  return (
    <div className="datapolitik-container">
      <h1>Persondatapolitik hos Parla Branding I/S</h1>
      
      <section>
        <p>Vi indhenter kun persondata i de tilfælde, hvor dette skulle være relevant for os, og vi vil kun indhente persondata, hvis det er relevant for din aktivitet hos Parla Branding I/S.</p>
        <p>Ved indsamling, behandling og anvendelse af dine persondata overholder vi altid alle relevante lovbestemmelser.</p>
        <p>Vi vil kun opbevare dine persondata, så længe vi enten er pålagt en juridisk forpligtelse hertil, eller så længe det er relevant for den hensigt, hvormed de blev indsamlet.</p>
      </section>

      <section>
        <h2>Oplysninger vi indsamler</h2>
        <p>Parla Branding I/S indsamler og behandler dine persondata, når du foretager dig følgende:</p>
        <ul>
          <li>Besøger vores hjemmeside</li>
        </ul>
      </section>

      <section>
        <h2>Den dataansvarlige</h2>
        <p>Den dataansvarlige for indsamling, behandling og anvendelse af dine personoplysninger på www.parlabranding.dk er Parla Branding I/S, Rebæk Søpark 5, 3. 441., 2650 Hvidovre, cvr nr. 43439928</p>
      </section>

      <section>
        <h2>Behandlingsgrundlag og formål</h2>
        <p>Dine almindelige kontaktoplysninger som navn og adresse indhenter vi for at kunne levere det produkt eller den ydelse, som du har købt hos os. Din e-mailadresse indhenter vi for at kunne sende dig en ordrebekræftelse samt en leveringsbekræftelse.</p>
        <p>Dine oplysninger (undtagen din e-mailadresse, hvis du har givet samtykke til behandling heraf med henblik på modtagelse af tilbud) vil blive slettet 5 år efter, at du har modtaget din vare eller ydelse.</p>
      </section>

      <section>
        <h2>Andre modtagere af personoplysninger</h2>
        <p>Vi sælger ikke dine persondata til tredjemand, og vi overfører ikke dine persondata til tredjelande.</p>
        <p>Vi har vores hjemmeside hos Firebase, som fungerer som vores databehandler. Alle persondata som du oplyser på vores hjemmeside vil blive opbevaret i Firebases datacentre.</p>
        <p>Vi har indgået databehandleraftaler med vores databehandlere, hvilket er vores garanti for, at de overholder gældende regler om beskyttelse af dine personoplysninger.</p>
      </section>

      <section>
        <h2>Dine rettigheder</h2>
        <p>Som den registrerede har du en række rettigheder, som vi til enhver tid skal sikre opfyldelse af. Du har ret til at anmode os om følgende:</p>
        <ul>
          <li>At få adgang til og få rettet/ændret dine persondata</li>
          <li>At få slettet persondata</li>
        </ul>
        <p>Du har derudover ret til at protestere over behandlingen af dine personlige data, og du har ret til at indgive klage til en databeskyttelsesmyndighed.</p>
        <p>Ønsker du ikke længere, at vi skal behandle dine personoplysninger, eller at vi skal begrænse behandlingen af dine personoplysninger, kan du også sende os en anmodning herom til vores e-mailadresse info@parlabranding.dk.</p>
      </section>
    </div>
  );
}

export default Datapolitik;
