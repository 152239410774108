import React, { useState } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import '../Styles/InvertSecondaryButton.css';

function InvertSecondaryButton({ text }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button 
      className="invert-secondary-button"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span>{text}</span>
      {isHovered && <HiArrowNarrowRight className="arrow-icon-invert" />}
    </button>
  );
}

export default InvertSecondaryButton;