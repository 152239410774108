import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Styles/Footer.css';
import PBLogo from '../images/PBBilleder/PBLogo.png'; // Importer logoet
import { useEffect } from 'react';

function Footer() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleLinkClick = (path) => {
    if (location.pathname === path) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section logo-section">
          <div className="logo-container">
            <img src={PBLogo} alt="Parla Branding Logo" className="footer-logo" />
          </div>
          <p className="footer-description">
            Parla Branding er et digitalt bureau i København og Holbæk. Vi skaber skræddersyede digitale løsninger, der styrker virksomheders online tilstedeværelse. Vores ekspertise omfatter webdesign, AI chatbots, SEO og Google Ads.
          </p>
        </div>
        <div className="footer-section">
          <h3>Services</h3>
          <nav>
            <Link to="/" onClick={() => handleLinkClick('/')}>Webdesign</Link>
            <Link to="/ai-chatbot" onClick={() => handleLinkClick('/ai-chatbot')}>AI Chatbot</Link>
            <Link to="/seo" onClick={() => handleLinkClick('/seo')}>SEO</Link>
            <Link to="/google-ads" onClick={() => handleLinkClick('/google-ads')}>Google Ads</Link>
          </nav>
        </div>
        <div className="footer-section">
          <h3>Sider</h3>
          <nav>
            <Link to="/" onClick={() => handleLinkClick('/')}>Forside</Link>
            <Link to="/cases" onClick={() => handleLinkClick('/cases')}>Cases</Link>
            <Link to="/om" onClick={() => handleLinkClick('/om')}>Om os</Link>
            <Link to="/kontakt" onClick={() => handleLinkClick('/kontakt')}>Kontakt</Link>
          </nav>
        </div>
        <div className="footer-section">
          <h3>Kontakt</h3>
          <p><i className="fas fa-phone"></i> +45 36 17 00 00</p>
          <p><i className="fas fa-envelope"></i> kontakt@parlabranding.dk</p>
          <p><i className="fas fa-building"></i> CVR: 43439928</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Parla Branding. Alle rettigheder forbeholdes.</p>
        <div className="social-icons">
          <a href="https://www.facebook.com/ParlaBranding" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/parlabranding.dk/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/parlabranding" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
