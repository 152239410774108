import React, { useEffect } from 'react';
import '../Styles/Nicolai.css';
import heroImage from '../images/hero/hero13.jpg';
import CTAButton from 'C:/Users/malik/pb/src/Components/CTAButton.js';
import CustomerTestimonials from 'C:/Users/malik/pb/src/Components/CustomerTestimonials.js';

function Nicolai() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const scrollToCalendar = () => {
    document.getElementById('calendar').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="meeting-page">
      <section className="meeting-hero" style={{ 
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <div className="meeting-hero-content">
          <h1>Book et møde med Nicolai</h1>
          <p>... og få et gratis udkast</p>
          
          <div className="mobile-cta">
            <CTAButton text="Book møde nu" onClick={scrollToCalendar} />
          </div>
          
          <div className="meeting-info-grid">
            <div className="info-item">
              <i className="fas fa-calendar"></i>
              <span>Book et møde nu</span>
            </div>
            <div className="process-arrow">
              <i className="fas fa-long-arrow-alt-right"></i>
            </div>
            <div className="info-item">
              <i className="fas fa-comments"></i>
              <span>Fortæl os hvad du ønsker</span>
            </div>
            <div className="process-arrow">
              <i className="fas fa-long-arrow-alt-right"></i>
            </div>
            <div className="info-item">
              <i className="fas fa-pencil-alt"></i>
              <span>Vi laver et udkast</span>
            </div>
            <div className="process-arrow">
              <i className="fas fa-long-arrow-alt-right"></i>
            </div>
            <div className="info-item">
              <i className="fas fa-check-circle"></i>
              <span>Du vælger om du vil gå videre med det</span>
            </div>
          </div>
          
          <CTAButton text="Book møde nu" onClick={scrollToCalendar} />
        </div>
      </section>

      <section className="meeting-calendar-section" id="calendar">
        <h2 className="calendar-title">Book et møde og få et gratis udkast nu</h2>
        <div className="meeting-calendar">
          <div 
            className="calendly-inline-widget" 
            data-url="https://calendly.com/parlabranding/book-et-mode?hide_gdpr_banner=1&primary_color=ffa200"
            style={{minWidth:"320px", height:"700px"}}
          ></div>
        </div>
      </section>

      <section className="kundeafsnit">
        <CustomerTestimonials />
      </section>
    </div>
  );
}

export default Nicolai;