import React, { useState, useEffect, useCallback, useRef } from 'react';
import { collection, getDocs, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { HiArrowNarrowRight } from 'react-icons/hi';
import '../Styles/Cases.css';

function Cases() {
  const [cases, setCases] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loaderRef = useRef(null);

  const fetchCases = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const casesRef = collection(db, 'cases');
      const q = lastVisible
        ? query(casesRef, orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(6))
        : query(casesRef, orderBy('createdAt', 'desc'), limit(6));

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        setHasMore(false);
        setLoading(false);
        return;
      }

      const newCases = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setCases(prevCases => {
        const uniqueCases = [...prevCases];
        newCases.forEach(newCase => {
          if (!uniqueCases.some(c => c.id === newCase.id)) {
            uniqueCases.push(newCase);
          }
        });
        return uniqueCases;
      });
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    } catch (error) {
      console.error('Fejl ved hentning af cases:', error);
    }
    setLoading(false);
  }, [lastVisible, loading, hasMore]);

  useEffect(() => {
    fetchCases();
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !loading) {
        fetchCases();
      }
    }, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [fetchCases, loading]);

  return (
    <div className="cases-page">
      <h1>Vores Cases</h1>
      <div className="cases-grid">
        {cases.map((caseItem) => (
          <Link to={`/case/${caseItem.id}`} key={caseItem.id} className="case-item">
            <div className="case-image-container">
              <div className="case-image">
                <img src={caseItem.image} alt={caseItem.title} />
              </div>
            </div>
            <div className="case-info">
              <div className="case-details">
                <h3>{caseItem.company}</h3>
                <p>{caseItem.displayLink}</p>
              </div>
              <div className="case-button" aria-label="Se mere">
                <HiArrowNarrowRight />
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div ref={loaderRef} style={{ height: '20px' }}></div>
      {loading && <div className="loading-spinner"></div>}
    </div>
  );
}

export default Cases;
