import React, { useState } from 'react';
import '../Styles/FAQ.css';
import CTAButton from './CTAButton';

const faqData = [
  {
    question: "Hvor lang tid tager det at lave en hjemmeside?",
    answer: "Tidsrammen varierer afhængigt af projektets kompleksitet. En simpel hjemmeside kan tage en uges tid, mens mere avancerede projekter kan tage et par måneder eller længere."
  },
  {
    question: "Hvad koster det at få lavet en hjemmeside?",
    answer: "Prisen afhænger af hjemmesidens omfang og funktionalitet. Vi giver altid et detaljeret tilbud baseret på dine specifikke behov."
  },
  {
    question: "Kan I hjælpe med at vedligeholde min hjemmeside efter lancering?",
    answer: "Ja, vi tilbyder løbende vedligeholdelse og support. Vi har forskellige servicepakker, der kan tilpasses dine behov, fra grundlæggende teknisk support til regelmæssige opdateringer af indhold."
  },
  {
    question: "Laver I også e-commerce hjemmesider?",
    answer: "Absolut! Vi har stor erfaring med at designe og udvikle e-commerce løsninger. Vi kan hjælpe dig med alt fra simple webshops til komplekse, skræddersyede e-handelsplatforme."
  },
  {
    question: "Er jeres hjemmesider mobilvenlige?",
    answer: "Ja, alle vores hjemmesider er fuldt responsive og optimeret til alle enheder, herunder smartphones, tablets og desktops. Vi sikrer en optimal brugeroplevelse uanset skærmstørrelse."
  }
];

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
      <div className="faq-container">
        <div className="faq-left">
          <h2>Har du spørgsmål?</h2>
          <p className="faq-subtitle">Vi har samlet svar på de mest almindelige spørgsmål. Kan du ikke finde svaret på dit spørgsmål, er du velkommen til at kontakte os.</p>
          <div className="faq-cta">
            <CTAButton text="Kontakt os" navigateTo="/kontakt" />
          </div>
        </div>
        <div className="faq-right">
          <div className="faq-accordion">
            {faqData.map((item, index) => (
              <div className={`faq-item ${activeIndex === index ? 'active' : ''}`} key={index}>
                <button
                  className="faq-question"
                  onClick={() => toggleAccordion(index)}
                >
                  {item.question}
                  <span className="faq-icon"></span>
                </button>
                <div className="faq-answer">
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Ny mobil CTA-knap */}
      <div className="faq-mobile-cta">
        <CTAButton text="Kontakt os" navigateTo="/kontakt" />
      </div>
    </section>
  );
}

export default FAQ;
