import React, { useState } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi'; // Tyndere pil-ikon
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import '../Styles/CTAButton.css';

function CTAButton({ text, navigateTo, onClick }) { // Tilføj onClick prop
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); // Initialiser useNavigate

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <button 
      className="cta-button"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <span>{text}</span>
      {isHovered && <HiArrowNarrowRight className="arrow-icon" />}
    </button>
  );
}

export default CTAButton;
