import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../Styles/CustomerTestimonials.css';

const CustomerTestimonials = () => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const testimonials = [
    { id: 1, name: "Charlotte", company: "www.psykologhagmund.dk", text: "Personlig kontakt, lydhørighed, fleksibilitet, ordentlighed. Har kun gode oplevelser og erfaringer.", rating: 5 },
    { id: 2, name: "Karoline", company: "www.lyngbypsykologen.dk", text: "Mark og Nicolai reddede mig på målstregen med at få lavet min hjemmeside under stort tidspres efter en virkelig dårlig oplevelse med en anden hjemmeside-mand, der ikke kunne levere det aftalte.", rating: 5 },
    { id: 3, name: "Heidi", company: "www.mayjoy.dk", text: "Supergodt forløb! Parla Branding har lavet 2 hjemmesider for mig, jeg er yderst tilfreds med både siderne og samarbejdet.", rating: 5 },
    { id: 3, name: "Svend", company: "www.relationspsykologerne.dk", text: "Enormt imødekommende og servicemindede mennesker. Hjælpsomme, effektive og ordentlige. Jeg kan simpelthen ikke sige noget negativt om samarbejdet og processen med at få skabt en ny hjemmeside til min virksomhed RelationsPsykologerne", rating: 5 },
  ];

  const renderStars = (rating) => {
    return '★'.repeat(rating);
  };

  const handleSlideClick = (index) => {
    setSelectedSlide(index);
  };

  return (
    <div className="kundeudtalelser">
      <Carousel 
        showArrows={!isMobile}
        infiniteLoop={true} 
        showThumbs={false} 
        showStatus={false} 
        autoPlay={true} 
        interval={5000}
        centerMode={!isMobile}
        centerSlidePercentage={isMobile ? 100 : 33.33}
        selectedItem={selectedSlide}
        onChange={setSelectedSlide}
        className="custom-carousel"
        swipeable={true}
        emulateTouch={true}
        swipeScrollTolerance={5}
        preventMovementUntilSwipeScrollTolerance={true}
        transitionTime={500}
      >
        {testimonials.map((testimonial, index) => (
          <div key={testimonial.id} className="testimonial-item" onClick={() => handleSlideClick(index)}>
            <div className={`testimonial-content ${index === selectedSlide ? 'selected' : ''}`}>
              <p className="testimonial-text">"{testimonial.text}"</p>
              <div>
                <h3 className="testimonial-name">{testimonial.name}</h3>
                <p className="testimonial-company">{testimonial.company}</p>
                <div className="testimonial-rating">{renderStars(testimonial.rating)}</div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomerTestimonials;