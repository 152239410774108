import React, { useEffect, useState } from 'react';
import '../Styles/CustomerLogos.css';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const CustomerLogos = () => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchLogos = async () => {
      const casesCollection = collection(db, 'cases');
      const casesSnapshot = await getDocs(casesCollection);
      const logosList = casesSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(item => item.logo)
        .map(item => ({ src: item.logo, alt: item.title || 'Kundelogo' }));
      setLogos(logosList);
    };

    fetchLogos();
  }, []);

  return (
    <div className="pb-customer-logos">
      <div className="pb-customer-logos-container">
        {logos.concat(logos).map((logo, index) => (
          <img key={index} src={logo.src} alt={logo.alt} className="pb-customer-logo" />
        ))}
      </div>
    </div>
  );
};

export default CustomerLogos;
