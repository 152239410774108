import React, { useEffect, useRef } from 'react';
import '../Styles/Om.css';
import CTAButton from '../Components/CTAButton';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import nicolaiImage from '../images/personale/Nicolai.jpg';
import markImage from '../images/personale/Mark.jpg';
import CustomerTestimonials from '../Components/CustomerTestimonials';
import CustomerLogos from '../Components/CustomerLogos';
import FAQ from '../Components/FAQ';
import InvertCTAButton from '../Components/InvertCTAButton';
import CasesBlack from '../Components/CasesBlack';

function Om() {
  const headerBackgroundRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (headerBackgroundRef.current) {
        headerBackgroundRef.current.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="om-page">
      <section className="om-header">
        <div className="om-header-background" ref={headerBackgroundRef}></div>
        <h1>Om Parla Branding</h1>
      </section>

      <section className="fordele">
        <h2>Vores værdier</h2>
        <p>Hos Parla Branding er vores værdier fundamentet for alt, hvad vi gør. De guider vores beslutninger, former vores kultur og definerer vores tilgang til at skabe værdi for vores kunder.</p>
        <div className="fordele-grid">
          <div className="fordel-item">
            <i className="fas fa-check-circle"></i>
            <h3>Kvalitet</h3>
            <p>Vi stræber efter excellence i alt, hvad vi laver, og leverer kun løsninger af højeste kvalitet.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-smile"></i>
            <h3>Tilfredshed</h3>
            <p>Vores kunders succes er vores succes. Vi går den ekstra mil for at sikre deres tilfredshed.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-lightbulb"></i>
            <h3>Innovation</h3>
            <p>Vi er altid på forkant med den nyeste teknologi og trends inden for digital markedsføring.</p>
          </div>
          <div className="fordel-item">
            <i className="fas fa-shield-alt"></i>
            <h3>Integritet</h3>
            <p>Ærlighed og gennemsigtighed er kernen i vores forretning og relationer.</p>
          </div>
        </div>
      </section>

      <section className="om-hero">
        <div className="om-hero-content">
          <h1>Vi skaber digitale løsninger der gør en forskel</h1>
          <p>Hos Parla Branding er vores mission at hjælpe virksomheder med at udnytte deres fulde potentiale online. Med vores ekspertise inden for webdesign, SEO, Google Ads og AI-chatbots, skaber vi skræddersyede digitale løsninger, der øger synlighed, engagement og vækst for vores kunder.</p>
          <div className="proces-afsnit-cta desktop-only">
            <CTAButton text="Kontakt os" navigateTo="/kontakt" />
          </div>
        </div>
        <div className="om-team-members">
          <div className="om-team-member">
            <img src={nicolaiImage} alt="Nicolai Malik Jeppesen" />
            <div className="om-team-member-info">
              <h3>Nicolai Malik Jeppesen</h3>
              <p>Stifter og medejer af Parla Branding</p>
              <p>Webdesigner</p>
              <p>Mail: malik@parlabranding.dk</p>
              <p>Telefon: +45 20 100 250</p>
            </div>
          </div>
          <div className="om-team-member">
            <img src={markImage} alt="Mark Abildgaard Christensen" />
            <div className="om-team-member-info">
              <h3>Mark Abildgaard Christensen</h3>
              <p>Stifter og medejer af Parla Branding</p>
              <p>Webdesigner</p>
              <p>Mail: mark@parlabranding.dk</p>
              <p>Telefon: +45 20 100 240</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <CTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section>
        <CustomerLogos />
      </section>

      <section className="proces-afsnit">
        <div className="proces-container">
          <div className="proces-title">
            <h2>Sådan skaber vi din hjemmeside</h2>
            <p>Vores proces er designet til at sikre, at din vision bliver til virkelighed. Vi samarbejder tæt med dig gennem hele forløbet for at skabe en hjemmeside, der ikke bare ser fantastisk ud, men også performer optimalt.</p>
            <div className="proces-afsnit-cta desktop-only">
              <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
            </div>
          </div>
          <div className="proces-trin">
            <div className="trin">
              <h3>1. Indledende møde</h3>
              <p>Vi starter med et møde, hvor vi diskuterer dine ønsker, behov og muligheder.</p>
            </div>
            <div className="trin">
              <h3>2. Tilbud</h3>
              <p>Vi udarbejder et tilbud baseret på dine krav, og ved accept påbegynder vi arbejdet.</p>
            </div>
            <div className="trin">
              <h3>3. Design</h3>
              <p>Vi designer og udvikler din hjemmeside, og du kan følge med og give feedback undervejs.</p>
            </div>
            <div className="trin">
              <h3>4. Lancering</h3>
              <p>Vi lancerer din hjemmeside og sørger for, at den bliver vist på Google.</p>
            </div>
          </div>
        </div>
        <div className="proces-afsnit-cta mobile-tablet-only">
          <InvertCTAButton text="Kontakt os" navigateTo="/kontakt" />
        </div>
      </section>

      <section className="kundeafsnit">
        <CustomerTestimonials />
      </section>

      <CasesBlack />

      <section className="ekstra-services">
        <div className="ekstra-services-content">
          <h2>Få mere ud af din hjemmeside</h2>
          <p>Udover skræddersyet webdesign tilbyder vi også:</p>
        </div>
        <div className="services-grid">
          <Link to="/webdesign" className="service-item">
            <div className="service-icon">
              <i className="fas fa-laptop-code"></i>
            </div>
            <div className="service-text">
              <h3>Webdesign</h3>
              <p>Skræddersyede hjemmesider der omsætter besøgende til kunder og styrker din brand identitet</p>
            </div>
          </Link>
          <Link to="/seo" className="service-item">
            <div className="service-icon">
              <i className="fas fa-search"></i>
            </div>
            <div className="service-text">
              <h3>SEO-optimering</h3>
              <p>Få din hjemmeside til tops i søgeresultaterne og øg din online synlighed</p>
            </div>
          </Link>
          <Link to="/google-ads" className="service-item">
            <div className="service-icon">
              <i className="fab fa-google"></i>
            </div>
            <div className="service-text">
              <h3>Google Ads-kampagner</h3>
              <p>Målrettet annoncering for hurtige resultater og øget trafik til din hjemmeside</p>
            </div>
          </Link>
          <Link to="/ai-chatbot" className="service-item">
            <div className="service-icon">
              <i className="fas fa-robot"></i>
            </div>
            <div className="service-text">
              <h3>AI Chatbot</h3>
              <p>Giv dine besøgende øjeblikkelig support døgnet rundt med intelligent automatisering</p>
            </div>
          </Link>
        </div>
      </section>

      <section className="faq-section">
        <FAQ />
      </section>
    </div>
  );
}

export default Om;