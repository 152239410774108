import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import '../Styles/CaseDetails.css';
import { HiArrowNarrowLeft } from 'react-icons/hi';

function CaseDetails() {
  const [caseData, setCaseData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCaseData() {
      const caseRef = doc(db, 'cases', id);
      const caseSnap = await getDoc(caseRef);
      
      if (caseSnap.exists()) {
        setCaseData({ id: caseSnap.id, ...caseSnap.data() });
      } else {
        console.log('Ingen case fundet med dette ID');
      }
    }

    fetchCaseData();
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };

  if (!caseData) {
    return <div className="i-loading">Indlæser case...</div>;
  }

  return (
    <div className="i-case-details-page">
      <button onClick={handleBack} className="i-back-button">
        <HiArrowNarrowLeft /> Tilbage
      </button>
      <div className="i-case-image-container">
        <img src={caseData.image} alt={caseData.company} className="i-case-image" />
        <a href={caseData.website} target="_blank" rel="noopener noreferrer" className="i-case-visit-button">
          Besøg
        </a>
      </div>
      <div className="i-case-info-row">
        <div className="i-case-info-item">
          <h3>Kunde</h3>
          <p>{caseData.company}</p>
        </div>
        <div className="i-case-info-item">
          <h3>År</h3>
          <p>{caseData.year || 'N/A'}</p>
        </div>
        <div className="i-case-info-item">
          <h3>Link</h3>
          <a href={caseData.website} target="_blank" rel="noopener noreferrer">{caseData.displayLink}</a>
        </div>
      </div>
      {caseData.testimonial && caseData.testimonial !== "." && (
        <div className="i-case-testimonial-container">
          <blockquote className="i-case-testimonial">
            "{caseData.testimonial}"
          </blockquote>
          <p className="i-case-testimonial-author">
            {caseData.testimonialAuthor || 'Anonym'}
          </p>
        </div>
      )}
      <p className="i-case-description">{caseData.description}</p>
      {(caseData.image_phone || caseData.image_tablet) && (
        <div className="i-case-responsive-images">
          {caseData.image_phone && (
            <div className="i-case-device-image-container">
              <div className="i-case-device-image-wrapper">
                <img src={caseData.image_phone} alt={`${caseData.company} mobilvisning`} className="i-case-device-image" />
              </div>
            </div>
          )}
          {caseData.image_tablet && (
            <div className="i-case-device-image-container">
              <div className="i-case-device-image-wrapper">
                <img src={caseData.image_tablet} alt={`${caseData.company} tabletvisning`} className="i-case-device-image" />
              </div>
            </div>
          )}
        </div>
      )}
      {((caseData.colors && caseData.colors.length > 0) || (caseData.fonts && caseData.fonts.length > 0)) && (
        <div className="i-case-design-details">
          <h2>Design detaljer</h2>
          {caseData.colors && caseData.colors.length > 0 && (
            <div className="i-case-colors">
              <h3>Farver</h3>
              <div className="i-color-samples">
                {caseData.colors.filter(color => color && color.trim() !== '').map((color, index) => (
                  <div key={index} className="i-color-sample">
                    <div className="i-color-box" style={{backgroundColor: color}}></div>
                    <span className="i-color-code">{color}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {caseData.fonts && caseData.fonts.length > 0 && (
            <div className="i-case-fonts">
              <h3>Skrifttyper</h3>
              <ul>
                {caseData.fonts.filter(font => font && font.trim() !== '').map((font, index) => (
                  <li key={index} style={{ fontFamily: font }}>
                    {font}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CaseDetails;