import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Beskeder() {
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newGroup, setNewGroup] = useState({ groupName: '' });
  const [isCreating, setIsCreating] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingGroupId, setDeletingGroupId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      
      if (!username || !apiKey) {
        throw new Error('Manglende credentials i .env filen');
      }

      const credentials = btoa(`${username}:${apiKey}`);
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = 'https://api.cpsms.dk/v2/listgroups';

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const groupsArray = Array.isArray(data) ? data : (data.groupId ? [data] : []);
      setGroups(groupsArray);
    } catch (err) {
      console.error('Fejl ved hentning af grupper:', err);
      setError('Der opstod en fejl ved hentning af SMS-grupper');
      setGroups([]);
    } finally {
      setLoading(false);
    }
  };

  const createGroup = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    setError(null);

    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      const credentials = btoa(`${username}:${apiKey}`);
      
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = 'https://api.cpsms.dk/v2/addgroup';

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
          groupName: newGroup.groupName
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Gruppe oprettet:', result);
      
      // Opdater gruppelisten
      await fetchGroups();
      
      // Nulstil form
      setNewGroup({ groupName: '' });
    } catch (err) {
      console.error('Fejl ved oprettelse af gruppe:', err);
      setError('Der opstod en fejl ved oprettelse af gruppen: ' + err.message);
    } finally {
      setIsCreating(false);
    }
  };

  const startEditing = (group) => {
    setEditingGroup({
      groupId: group.groupId,
      groupName: group.groupName
    });
  };

  const cancelEditing = () => {
    setEditingGroup(null);
    setError(null);
  };

  const updateGroup = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    setError(null);

    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      const credentials = btoa(`${username}:${apiKey}`);
      
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = 'https://api.cpsms.dk/v2/updategroup';

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
          groupId: editingGroup.groupId,
          groupName: editingGroup.groupName
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Gruppe opdateret:', result);
      
      // Opdater gruppelisten
      await fetchGroups();
      
      // Afslut redigering
      setEditingGroup(null);
    } catch (err) {
      console.error('Fejl ved opdatering af gruppe:', err);
      setError('Der opstod en fejl ved opdatering af gruppen: ' + err.message);
    } finally {
      setIsEditing(false);
    }
  };

  const deleteGroup = async (groupId) => {
    if (!window.confirm('Er du sikker på, at du vil slette denne gruppe?')) {
      return;
    }

    setIsDeleting(true);
    setDeletingGroupId(groupId);
    setError(null);

    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      const credentials = btoa(`${username}:${apiKey}`);
      
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = 'https://api.cpsms.dk/v2/deletegroup';

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
          groupId: groupId
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Gruppe slettet:', result);
      
      // Opdater gruppelisten
      await fetchGroups();
    } catch (err) {
      console.error('Fejl ved sletning af gruppe:', err);
      setError('Der opstod en fejl ved sletning af gruppen: ' + err.message);
    } finally {
      setIsDeleting(false);
      setDeletingGroupId(null);
    }
  };

  if (loading) {
    return <div>Indlæser SMS-grupper...</div>;
  }

  return (
    <div className="beskeder-container">
      <h2>SMS Grupper</h2>
      
      {/* Opret ny gruppe formular */}
      <div className="create-group-form">
        <h3>Opret ny gruppe</h3>
        <form onSubmit={createGroup}>
          <div className="form-group">
            <input
              type="text"
              value={newGroup.groupName}
              onChange={(e) => setNewGroup({ groupName: e.target.value })}
              placeholder="Indtast gruppenavn"
              required
            />
          </div>
          <button 
            type="submit" 
            disabled={isCreating || !newGroup.groupName.trim()}
          >
            {isCreating ? 'Opretter...' : 'Opret gruppe'}
          </button>
        </form>
      </div>

      {error && <div className="error-message">{error}</div>}

      {/* Liste over grupper */}
      <div className="groups-list">
        {groups.length === 0 ? (
          <p>Ingen SMS-grupper fundet</p>
        ) : (
          groups.map(group => (
            <div key={group.groupId} className="group-item">
              {editingGroup && editingGroup.groupId === group.groupId ? (
                <form onSubmit={updateGroup} className="edit-group-form">
                  <input
                    type="text"
                    value={editingGroup.groupName}
                    onChange={(e) => setEditingGroup({
                      ...editingGroup,
                      groupName: e.target.value
                    })}
                    placeholder="Gruppenavn"
                    required
                  />
                  <div className="edit-buttons">
                    <button 
                      type="submit"
                      disabled={isEditing || !editingGroup.groupName.trim()}
                    >
                      {isEditing ? 'Gemmer...' : 'Gem'}
                    </button>
                    <button 
                      type="button"
                      onClick={cancelEditing}
                      disabled={isEditing}
                    >
                      Annuller
                    </button>
                  </div>
                </form>
              ) : (
                <>
                  <div 
                    className="group-header"
                    onClick={() => navigate(`/inbox/${group.groupId}`)}
                  >
                    <h3>{group.groupName}</h3>
                    <p>Gruppe ID: {group.groupId}</p>
                  </div>
                  <div className="group-buttons">
                    <button 
                      onClick={(e) => {
                        e.stopPropagation(); // Forhindrer navigation ved klik på knapper
                        startEditing(group);
                      }}
                      className="edit-button"
                      disabled={isDeleting}
                    >
                      Rediger
                    </button>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation(); // Forhindrer navigation ved klik på knapper
                        deleteGroup(group.groupId);
                      }}
                      className="delete-button"
                      disabled={isDeleting || deletingGroupId === group.groupId}
                    >
                      {deletingGroupId === group.groupId ? 'Sletter...' : 'Slet'}
                    </button>
                  </div>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Beskeder;
