import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAQoIY5cqMeJDFH9M12DOXfbxD8ieCpJM0",
  authDomain: "parla-branding.firebaseapp.com",
  projectId: "parla-branding",
  storageBucket: "parla-branding.appspot.com",
  messagingSenderId: "883616828358",
  appId: "1:883616828358:web:4b2488a7a6710db83d00c7",
  measurementId: "G-KPGD7WZ90D"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };