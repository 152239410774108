import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/inbox.css';

function Inbox() {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [groupInfo, setGroupInfo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newContact, setNewContact] = useState({
    phoneNumber: '',
    contactName: ''
  });
  const [isCreating, setIsCreating] = useState(false);
  const [editingContact, setEditingContact] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    fetchContacts();
  }, [groupId]);

  const fetchContacts = async () => {
    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      const credentials = btoa(`${username}:${apiKey}`);
      
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = `https://api.cpsms.dk/v2/listcontacts/${groupId}`;

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setContacts(Array.isArray(data) ? data : []);

      // Hent gruppeinfo
      const groupResponse = await fetch(proxyUrl + 'https://api.cpsms.dk/v2/listgroups', {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        }
      });

      if (groupResponse.ok) {
        const groupData = await groupResponse.json();
        const group = Array.isArray(groupData) 
          ? groupData.find(g => g.groupId === parseInt(groupId))
          : (groupData.groupId === parseInt(groupId) ? groupData : null);
        setGroupInfo(group);
      }
    } catch (err) {
      console.error('Fejl ved hentning af kontakter:', err);
      setError('Der opstod en fejl ved hentning af kontakter');
    } finally {
      setLoading(false);
    }
  };

  const createContact = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    setError(null);

    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      const credentials = btoa(`${username}:${apiKey}`);
      
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = 'https://api.cpsms.dk/v2/addcontact';

      // Sikrer at telefonnummeret starter med landekode
      const formattedPhone = newContact.phoneNumber.startsWith('45') 
        ? newContact.phoneNumber 
        : '45' + newContact.phoneNumber;

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
          groupId: parseInt(groupId),
          phoneNumber: formattedPhone,
          contactName: newContact.contactName
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Kontakt oprettet:', result);
      
      // Nulstil form og opdater kontaktlisten
      setNewContact({ phoneNumber: '', contactName: '' });
      await fetchContacts();
    } catch (err) {
      console.error('Fejl ved oprettelse af kontakt:', err);
      setError('Der opstod en fejl ved oprettelse af kontakten: ' + err.message);
    } finally {
      setIsCreating(false);
    }
  };

  const startEditing = (contact) => {
    setEditingContact({
      phoneNumber: contact.phoneNumber,
      contactName: contact.contactName
    });
  };

  const cancelEditing = () => {
    setEditingContact(null);
    setError(null);
  };

  const updateContact = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    setError(null);

    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      const credentials = btoa(`${username}:${apiKey}`);
      
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = 'https://api.cpsms.dk/v2/updatecontact';

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
          groupId: parseInt(groupId),
          phoneNumber: editingContact.phoneNumber,
          contactName: editingContact.contactName
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Kontakt opdateret:', result);
      
      // Opdater kontaktlisten og afslut redigering
      await fetchContacts();
      setEditingContact(null);
    } catch (err) {
      console.error('Fejl ved opdatering af kontakt:', err);
      setError('Der opstod en fejl ved opdatering af kontakten: ' + err.message);
    } finally {
      setIsEditing(false);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!selectedContact || !newMessage.trim()) return;

    setIsSending(true);
    setError(null);

    try {
      const username = process.env.REACT_APP_CPSMS_USERNAME;
      const apiKey = process.env.REACT_APP_CPSMS_API_KEY;
      const credentials = btoa(`${username}:${apiKey}`);
      
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const apiUrl = 'https://api.cpsms.dk/v2/send';

      const response = await fetch(proxyUrl + apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
          to: selectedContact.phoneNumber,
          message: newMessage,
          from: "ParlaVVS" // Eller anden afsender
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Besked sendt:', result);
      
      // Nulstil beskedfeltet efter succes
      setNewMessage('');
    } catch (err) {
      console.error('Fejl ved afsendelse af besked:', err);
      setError('Der opstod en fejl ved afsendelse af beskeden: ' + err.message);
    } finally {
      setIsSending(false);
    }
  };

  if (loading) {
    return <div className="inbox-container">Indlæser kontakter...</div>;
  }

  return (
    <div className="inbox-container">
      <div className="inbox-header">
        <button 
          className="inbox-back-button" 
          onClick={() => navigate('/admin?tab=beskeder')}
        >
          ← Tilbage til grupper
        </button>
        <h2>{groupInfo ? groupInfo.groupName : `Gruppe ${groupId}`}</h2>
      </div>

      <div className="inbox-layout">
        {/* Venstre kolonne - Kontaktliste */}
        <div className="inbox-contacts-sidebar">
          <div className="inbox-create-contact-form">
            <h3>Tilføj ny kontakt</h3>
            <form onSubmit={createContact}>
              <div className="inbox-form-group">
                <input
                  type="tel"
                  value={newContact.phoneNumber}
                  onChange={(e) => setNewContact({
                    ...newContact,
                    phoneNumber: e.target.value.replace(/[^0-9]/g, '') // Kun tal tilladt
                  })}
                  placeholder="Telefonnummer (8 cifre)"
                  pattern="[0-9]{8}"
                  required
                />
              </div>
              <div className="inbox-form-group">
                <input
                  type="text"
                  value={newContact.contactName}
                  onChange={(e) => setNewContact({
                    ...newContact,
                    contactName: e.target.value
                  })}
                  placeholder="Kontaktnavn"
                  required
                />
              </div>
              <button 
                type="submit" 
                disabled={isCreating || !newContact.phoneNumber || !newContact.contactName}
              >
                {isCreating ? 'Opretter...' : 'Opret kontakt'}
              </button>
            </form>
          </div>

          <div className="inbox-contacts-list">
            {contacts.length === 0 ? (
              <p className="inbox-no-contacts">Ingen kontakter i denne gruppe</p>
            ) : (
              contacts.map((contact, index) => (
                <div 
                  key={index} 
                  className={`inbox-contact-item ${selectedContact?.phoneNumber === contact.phoneNumber ? 'selected' : ''}`}
                  onClick={() => setSelectedContact(contact)}
                >
                  {editingContact && editingContact.phoneNumber === contact.phoneNumber ? (
                    <form onSubmit={updateContact} className="inbox-edit-contact-form">
                      <div className="inbox-form-group">
                        <input
                          type="text"
                          value={editingContact.contactName}
                          onChange={(e) => setEditingContact({
                            ...editingContact,
                            contactName: e.target.value
                          })}
                          placeholder="Kontaktnavn"
                          required
                        />
                      </div>
                      <p className="inbox-phone-number">Telefon: {contact.phoneNumber}</p>
                      <div className="inbox-edit-buttons">
                        <button 
                          type="submit"
                          disabled={isEditing || !editingContact.contactName.trim()}
                        >
                          {isEditing ? 'Gemmer...' : 'Gem'}
                        </button>
                        <button 
                          type="button"
                          onClick={cancelEditing}
                          disabled={isEditing}
                        >
                          Annuller
                        </button>
                      </div>
                    </form>
                  ) : (
                    <>
                      <div className="inbox-contact-info">
                        <h3>{contact.contactName}</h3>
                        <p className="inbox-phone-number">{contact.phoneNumber}</p>
                      </div>
                      <div className="inbox-contact-buttons">
                        <button 
                          onClick={() => startEditing(contact)}
                          className="inbox-edit-button"
                        >
                          Rediger
                        </button>
                        <button 
                          onClick={() => setSelectedContact(contact)}
                          className="inbox-send-message-button"
                        >
                          Send besked
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))
            )}
          </div>
        </div>

        {/* Højre kolonne - Chat vindue */}
        <div className="inbox-chat-window">
          {selectedContact ? (
            <>
              <div className="inbox-chat-header">
                <h3>{selectedContact.contactName}</h3>
                <p>{selectedContact.phoneNumber}</p>
              </div>
              
              <div className="inbox-message-form">
                <form onSubmit={sendMessage}>
                  <textarea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Skriv din besked her..."
                    maxLength={1530}
                    required
                  />
                  <div className="inbox-message-form-footer">
                    <span className="inbox-char-count">
                      {newMessage.length}/1530 tegn
                    </span>
                    <button 
                      type="submit"
                      disabled={isSending || !newMessage.trim()}
                    >
                      {isSending ? 'Sender...' : 'Send SMS'}
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div className="inbox-no-contact-selected">
              <p>Vælg en kontakt for at sende en besked</p>
            </div>
          )}
        </div>
      </div>

      {error && <div className="inbox-error-message">{error}</div>}
    </div>
  );
}

export default Inbox;
